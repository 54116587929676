<template>
  <div class="vip-video-parser">
    <h2>请输入视频网址：</h2>
    <a-input v-model:value="videoUrl" placeholder="请输入视频链接" />
    <a-button @click="clearInput" class="custom-button">重置</a-button>

    <div class="button-group">
      <a-button
        class="custom-button"
        v-for="(url, index) in headUrls"
        :key="index"
        @click="openUrl(index)"
      >
        线路{{ index + 1 }}</a-button
      >
    </div>
  </div>

  <div
    class="components-page-header-demo-responsive"
    style="border: 1px solid rgb(235, 237, 240)"
  >
    <a-page-header
      title="明凡编程宝典简介"
      sub-title="sub"
      @back="() => $router.go(-1)"
    >
      <template #extra>
        <context-holder />
        <a-button key="3" @click="info">查询</a-button>
        <a-button key="2" @click="info">Operation</a-button>
        <a-button key="1" @click="info" type="primary">Primary</a-button>
      </template>
      <template #footer>
        <a-tabs>
          <a-tab-pane key="1" tab="Details" />
          <a-tab-pane key="2" tab="Rule" />
        </a-tabs>
        <!--        空数据-->
        <div id="null-data">
          <a-empty />
        </div>
      </template>
      <div class="content">
        <div class="main">
          <a-descriptions size="small" :column="2">
            <a-descriptions-item label="作者">MingFan</a-descriptions-item>
            <a-descriptions-item label="Association">
              <a>02196668</a>
            </a-descriptions-item>
            <a-descriptions-item label="Creation Time"
              >2025-01-01</a-descriptions-item
            >
            <a-descriptions-item label="Effective Time"
              >always</a-descriptions-item
            >
            <a-descriptions-item label="Remarks">
              Xinyang City, Henan Province, China
            </a-descriptions-item>
          </a-descriptions>
        </div>
        <div class="extra">
          <div
            :style="{
              display: 'flex',
              width: 'max-content',
              justifyContent: 'flex-end',
            }"
          >
            <a-statistic
              title="Status"
              value="developing"
              :style="{
                marginRight: '32px',
              }"
            />
          </div>
        </div>
      </div>
    </a-page-header>
  </div>
</template>
<script setup lang="ts">
import { ref } from "vue";
const videoUrl = ref<string>("");

// 解析线路的 URL 前缀
const headUrls = [
  "https://www.ckplayer.vip/jiexi/?url=",
  "https://jx.yparse.com/index.php?url=",
  "https://www.8090g.cn/?url=",
  "https://www.pouyun.com/?url=",
  "https://jx.xmflv.com/?url=",
  "https://im1907.top/?jx=",
  "https://jx.aidouer.net/?url=",
  "https://www.yemu.xyz/?url=",
  "https://jx.m3u8.tv/jiexi/?url=",
  "https://api.qianqi.net/vip/?url=",
];

// 清空输入框
const clearInput = () => {
  videoUrl.value = "";
};

// 打开链接
const openUrl = (index) => {
  const fullUrl = headUrls[index] + encodeURIComponent(videoUrl.value);
  window.open(fullUrl, "_blank");
};

/**
 *  按钮点击的全局提醒
 */
import { message } from "ant-design-vue";
const [messageApi, contextHolder] = message.useMessage();

const info = () => {
  messageApi.info("暂无数据");
};
</script>
<style scoped>
.components-page-header-demo-responsive {
  padding-bottom: 20px;
}
.components-page-header-demo-responsive tr:last-child td {
  padding-bottom: 0;
}
#components-page-header-demo-responsive .content {
  display: flex;
}
#components-page-header-demo-responsive .ant-statistic-content {
  font-size: 20px;
  line-height: 28px;
}
@media (max-width: 576px) {
  #components-page-header-demo-responsive .content {
    display: block;
  }

  #components-page-header-demo-responsive .main {
    width: 100%;
    margin-bottom: 12px;
  }

  #components-page-header-demo-responsive .extra {
    width: 100%;
    margin-left: 0;
    text-align: left;
  }
}
#null-data {
  margin: 50px;
}
.vip-video-parser {
  width: 380px;
  margin: 20px auto;
  text-align: center;
}

h2 {
  color: #24abf2;
}

input {
  margin: 10px 0;
  padding: 8px;
  width: 70%;
}

.custom-button {
  margin: 5px;
}

.button-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
</style>
