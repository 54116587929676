import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, unref as _unref, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "home-txt-down" }
const _hoisted_2 = { class: "home-txt-down" }
const _hoisted_3 = { class: "home-txt-down" }
const _hoisted_4 = { class: "ant-upload-drag-icon" }
const _hoisted_5 = { class: "home-time-line" }

import { ref } from "vue";
import { InboxOutlined } from "@ant-design/icons-vue";
import type { UploadChangeParam } from "ant-design-vue";
import { message } from "ant-design-vue";

const msg = "明凡云开发中，敬请期待";
const text = `明凡云-明凡智能云—— 咸鱼梦想家，让梦想照进现实`;
const text2 = `亨利·福特是美国的工业家、工程师、发明家，也是福特汽车公司的创始人。他的这句名言强调了失败对于成功的重要性。福特本人在成功之前也经历了多次失败，他的早期汽车公司如底特律汽车公司和亨利·福特公司都未能成功。但他没有放弃，最终在1903年与其他人一起创立了福特汽车公司，并推出了革命性的T型车，这不仅改变了汽车行业，也推动了现代工业生产方式的发展`;


export default /*@__PURE__*/_defineComponent({
  __name: 'HomePage',
  setup(__props) {

const fileList = ref([]);
const handleChange = (info: UploadChangeParam) => {
  const status = info.file.status;
  if (status !== "uploading") {
    console.log(info.file, info.fileList);
  }
  if (status === "done") {
    message.success(`${info.file.name} file uploaded successfully.`);
  } else if (status === "error") {
    message.error(`${info.file.name} file upload failed.`);
  }
};
function handleDrop(e: DragEvent) {
  console.log(e);
}

/**
 *   OBS 文件上key value
 *   access_key_id: process.env.1DTJLPUWDVCZDBKB8HZ3,
 *   secret_access_key: process.env.rkhQTsDMA85gAlQlBQeLhD91PWrmCrlBJuBmtiBx,
 */

/**
 * 倒计时组件获取距离秒数
 * 示例
 * const deadline = Date.now() + 1000 * 60 * 60 * 24 * 14;
 */
// 创建一个 Date 对象，表示 2024 年 12 月 30 日
const targetDate = new Date(2025, 3, 1); // 月份是从 0 开始的，所以 11 表示 12 月
// 获取目标日期的时间戳
const targetTimestamp = targetDate.getTime();

// 获取当前时间的时间戳
const nowTimestamp = Date.now();
console.log(nowTimestamp);
// 计算距离目标日期的毫秒数

const deadline = Date.now() + targetTimestamp - nowTimestamp;

return (_ctx: any,_cache: any) => {
  const _component_a_statistic_countdown = _resolveComponent("a-statistic-countdown")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_collapse_panel = _resolveComponent("a-collapse-panel")!
  const _component_a_collapse = _resolveComponent("a-collapse")!
  const _component_a_upload_dragger = _resolveComponent("a-upload-dragger")!
  const _component_a_timeline_item = _resolveComponent("a-timeline-item")!
  const _component_a_timeline = _resolveComponent("a-timeline")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", { id: "homePage" }, [
      _createElementVNode("h1", { class: "com_file" }, _toDisplayString(msg))
    ]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_a_col, {
        span: 24,
        style: {"margin-top":"32px","display":"flex","justify-content":"center","align-items":"center"}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_statistic_countdown, {
            title: "预估下一期开发上线周期",
            value: deadline,
            format: "D 天 H 时 m 分 s 秒"
          })
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_a_collapse, { accordion: "" }, {
        default: _withCtx(() => [
          _createVNode(_component_a_collapse_panel, {
            key: "1",
            header: "明凡云简介"
          }, {
            default: _withCtx(() => [
              _createElementVNode("p", null, _toDisplayString(text))
            ]),
            _: 1
          }),
          _createVNode(_component_a_collapse_panel, {
            key: "2",
            header: "成功的人是从失败中走出来的，而不是从成功中走出来的"
          }, {
            default: _withCtx(() => [
              _createElementVNode("p", null, _toDisplayString(text2))
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_a_upload_dragger, {
        fileList: fileList.value,
        "onUpdate:fileList": _cache[0] || (_cache[0] = ($event: any) => ((fileList).value = $event)),
        name: "file",
        multiple: true,
        onChange: handleChange,
        onDrop: handleDrop
      }, {
        default: _withCtx(() => [
          _createElementVNode("p", _hoisted_4, [
            _createVNode(_unref(InboxOutlined))
          ]),
          _cache[1] || (_cache[1] = _createElementVNode("p", { class: "ant-upload-text" }, "明凡云上传样例", -1)),
          _cache[2] || (_cache[2] = _createElementVNode("p", { class: "ant-upload-hint" }, "这是一个上传demo样例，等待后端API接入。", -1))
        ]),
        _: 1
      }, 8, ["fileList"])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_a_timeline, null, {
        default: _withCtx(() => [
          _createVNode(_component_a_timeline_item, null, {
            default: _withCtx(() => _cache[3] || (_cache[3] = [
              _createTextVNode("2025-01-08 明凡云项目规划")
            ])),
            _: 1
          }),
          _createVNode(_component_a_timeline_item, null, {
            default: _withCtx(() => _cache[4] || (_cache[4] = [
              _createTextVNode("2025-01-10 明凡云项目启动")
            ])),
            _: 1
          }),
          _createVNode(_component_a_timeline_item, null, {
            default: _withCtx(() => _cache[5] || (_cache[5] = [
              _createTextVNode("2025-02-8 明凡云项目雏形完工")
            ])),
            _: 1
          }),
          _createVNode(_component_a_timeline_item, null, {
            default: _withCtx(() => _cache[6] || (_cache[6] = [
              _createTextVNode("2025-02-10 明凡云项目进入下一个阶段")
            ])),
            _: 1
          })
        ]),
        _: 1
      })
    ])
  ], 64))
}
}

})