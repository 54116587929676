<template>
  <div id="basicLayout">
    <a-layout style="min-height: 100vh">
      <a-layout-header class="header">
        <GlobalHeader />
      </a-layout-header>
      <a-layout-content class="content">
        <!--        顶端按钮-->
        <a-float-button-group shape="circle" :style="{ right: '24px' }">
          <a-back-top :visibility-height="0" />
        </a-float-button-group>
        <router-view />
        <!--        明凡云开发中，敬请期待！-->
        <!--        {{ JSON.stringify(loginUserStore.loginUser) }}-->
      </a-layout-content>
      <a-layout-footer class="footer">
        <p>
          © <span>{{ currentYear }}</span> MingFan. All rights reserved.
        </p>
        <a href="https://mingfancloud.cn" target="_blank"> 明凡云 by 明凡 </a
        >|<a href="https://beian.miit.gov.cn/" target="_blank">
          豫ICP备2023032536号
        </a></a-layout-footer
      >
    </a-layout>
  </div>
</template>

<script lang="ts" setup>
import GlobalHeader from "@/components/GlobalHeader.vue";

const currentYear = new Date().getFullYear();
//登陆状态信息
// import { useLoginUserStore } from "@/store/useLoginUserStore";
// const loginUserStore = useLoginUserStore();
//测试代码
// import { getCurrentUser } from "@/api/user";
// getCurrentUser().then((res) => {
//   console.log(res);
// });
</script>

<style scoped>
#basicLayout .header {
  padding-inline: 20px;
  margin-bottom: 16px;
  color: unset;
  background: white;
}
#basicLayout .footer {
  /**
   * background: efefef;
   * padding: 16px;
   */
  background: #e1dce8;
  padding: 8px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
}
#basicLayout .content {
  background: linear-gradient(to right, #fefefe, #fff);
  margin-bottom: 28px;
  padding: 20px;
}
</style>
