import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "birthday" }

const msg = "明凡云开发中，敬请期待";

/**
 * 倒计时组件获取距离明凡生日秒数
 * 示例
 * const deadline = Date.now() + 1000 * 60 * 60 * 24 * 14;
 */
// 创建一个 Date 对象，表示 2024 年 12 月 30 日

export default /*@__PURE__*/_defineComponent({
  __name: 'CountdownPage',
  setup(__props) {

const birthdayDate = new Date(2025, 2, 18); // 月份是从 0 开始的，所以 11 表示 12 月
// 获取目标日期的时间戳
const birthdayTimestamp = birthdayDate.getTime();

// 获取当前时间的时间戳
const nowBirthdayTimestamp = Date.now();
// 计算距离目标日期的毫秒数

const BirthdayTime = Date.now() + birthdayTimestamp - nowBirthdayTimestamp;

return (_ctx: any,_cache: any) => {
  const _component_a_statistic_countdown = _resolveComponent("a-statistic-countdown")!
  const _component_a_col = _resolveComponent("a-col")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", { id: "homePage" }, [
      _createElementVNode("h1", { class: "com_file" }, _toDisplayString(msg))
    ]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_a_col, {
        span: 24,
        style: {"margin-top":"32px","display":"flex","justify-content":"center","align-items":"center"}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_statistic_countdown, {
            title: "距离明凡生日还剩",
            value: BirthdayTime,
            format: "D 天 H 时 m 分 s 秒"
          })
        ]),
        _: 1
      })
    ])
  ], 64))
}
}

})